import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import './index.scss';
import Busy from '../../Components/Busy';
import { ReactComponent as IconArrow } from '../../assets/images/arrow.svg';
import IconCamera from '../../assets/images/camera.png';
import IconMedical from '../../assets/images/medical.png';
import moment from 'moment';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { EventContext } from '../../Contexts/Events';
import Modal from '../../Components/Modal';
import { updatedDiff } from 'deep-object-diff';

function TimetableView() {
    const [lessonState, setLessonState] = useState({
        busy: true,
        updating: false,
        attendances: [] as any,
        medicalModal: false as any,
        criteriaModal: false as any
    });

    const loading = useRef(true);
    const { courseId } = useParams();
    const navigate = useNavigate();
    const eventHandler = useContext(EventContext);

    useEffect(() => {
        const attendancesReceived = (attendances: any = undefined) => {
            if (!attendances) {
                navigate('/', {
                    state: {
                        error: {
                            title: 'Error',
                            message: 'The requested lesson could not be loaded.'
                        }
                    }
                });
                return;
            }
            setLessonState(state => ({...state, updating: false, busy: false, attendances}));
        }

        if (loading.current) {
            eventHandler.trigger('attendances:request', courseId, moment().format('YYYY-MM-DD'));
            loading.current = false;
        }

        eventHandler.on('attendances:receive:' + courseId, attendancesReceived);
        return () => {
            eventHandler.off('attendances:receive:' + courseId, attendancesReceived);
        }
    }, [lessonState, courseId, navigate, eventHandler]);

    const toggleStudent = (idx: any) => {
        lessonState.attendances.forEach((a: any, _idx: any) => {
            if (_idx !== idx) {
                a.open = false;
            }
        });
        lessonState.attendances[idx].open = !!!lessonState.attendances[idx].open;
        setLessonState((state) => ({...state, attendances: lessonState.attendances}));
    }

    const toggleCriteria = (idx: any) => {
        Object.keys(lessonState.attendances[lessonState.criteriaModal].criteria).forEach((_idx: any) => {
            if (_idx !== idx) {
                lessonState.attendances[lessonState.criteriaModal].criteria[_idx].open = false;
            }
        });
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].open = !!!lessonState.attendances[lessonState.criteriaModal].criteria[idx].open;
        setLessonState((state) => ({...state, attendances: lessonState.attendances}));
    }

    const updateAttendanceNotes = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            lesson_notes: e.target.value
        });
        lessonState.attendances[idx].lesson_notes = e.target.value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateAttendanceStatus = (idx: any, e: any) => {
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[idx].id, {
            lesson_status: (e.target.checked ? 'present' : 'absent')
        });
        lessonState.attendances[idx].lesson_status = (e.target.value ? 'present' : 'absent');
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCriteriaStatus = (idx: any, e: any) => {
        let changes: any = {};
        changes['criteria.' + lessonState.attendances[lessonState.criteriaModal].criteria[idx].id + '.achieved_at'] = moment().format('YYYY-MM-DD HH:mm:ss');
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[lessonState.criteriaModal].id, changes);
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].achieved_at = moment().format('YYYY-MM-DD HH:mm:ss');
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    const updateCriteriaNotes = (idx: any, e: any) => {
        let changes: any = {};
        changes['criteria.' + lessonState.attendances[lessonState.criteriaModal].criteria[idx].id + '.notes'] = e.target.value;
        eventHandler.trigger('actions:add', 'lesson', lessonState.attendances[lessonState.criteriaModal].id, changes);
        lessonState.attendances[lessonState.criteriaModal].criteria[idx].notes = e.target.value;
        setLessonState({...lessonState, attendances: lessonState.attendances});
    }

    return (
        <div className={`LessonView ${lessonState.busy ? 'is--busy' : ''}`}>
            {lessonState.busy && (
                <Busy />
            )}
            {!lessonState.busy && (
                <>
                    {lessonState.medicalModal !== false && (
                        <Modal onDismiss={() => setLessonState({ ...lessonState, medicalModal: false })} title="Medical Notes">{lessonState.medicalModal}</Modal>
                    )}
                    {lessonState.criteriaModal !== false && (
                        <div className="CriteriaModal">
                            <h3>Level/Badge Progress</h3>
                            <h4>{lessonState.attendances[lessonState.criteriaModal].first_name} {lessonState.attendances[lessonState.criteriaModal].last_name} <span>- {lessonState.attendances[lessonState.criteriaModal].level}</span></h4>
                            {Object.keys(lessonState.attendances[lessonState.criteriaModal].criteria).map((idx: any) => {
                                const criteria = lessonState.attendances[lessonState.criteriaModal].criteria[idx];
                                return (
                                    <div className={'LessonCriteriaRow' + (criteria.open ? ' open' : '')} key={idx}>
                                        <div className="LessonCriteriaRowHeader">
                                            <div className="vc-toggle-container">
                                                <label className="vc-switch">
                                                    <input type="checkbox" onChange={(e) => updateCriteriaStatus(idx, e)} defaultChecked={!!criteria.achieved_at} className="vc-switch-input" />
                                                    <span 
                                                        data-on="Done" 
                                                        data-off="To do"
                                                        className="vc-switch-label"
                                                    ></span>
                                                    <span className="vc-handle"></span>
                                                </label>
                                            </div>
                                            <div className="name">{criteria.criteria}</div>
                                            <button type="button" onClick={(e) => toggleCriteria(idx)} className="toggle">
                                                <IconArrow width={6} height={10} />
                                            </button>
                                        </div>
                                        <div className="LessonCriteriaRowBody">
                                            <textarea placeholder="Criteria Notes" rows={6} onBlur={(e) => updateCriteriaNotes(idx, e)} defaultValue={criteria.notes}></textarea>
                                        </div>
                                    </div>
                                );
                            })}
                            <button type="button" className="Back" onClick={(e) => setLessonState({...lessonState, criteriaModal: false})}>
                                <span>
                                    <IconArrow width={15.91} height={24.6} />
                                </span>
                                Back to Lesson
                            </button>
                        </div>
                    )}
                    {lessonState.attendances.length > 0 && (
                        <div className="LessonViewDetail">
                            <h3>{lessonState.attendances[0].course}</h3>
                            {lessonState.attendances.map((attendance: any, idx: any) => {
                                return (
                                    <div className={'LessonAttendanceRow' + (attendance.open ? ' open' : '')} key={idx}>
                                        <div className="LessonAttendanceRowHeader">
                                            <div className="vc-toggle-container">
                                                <label className="vc-switch">
                                                    <input type="checkbox" onChange={(e) => updateAttendanceStatus(idx, e)} defaultChecked={attendance.lesson_status === 'present'} className="vc-switch-input" />
                                                    <span 
                                                        data-on="Present" 
                                                        data-off="Absent"
                                                        className="vc-switch-label"
                                                    ></span>
                                                    <span className="vc-handle"></span>
                                                </label>
                                            </div>
                                            <div className="name">{attendance.first_name} {attendance.last_name}</div>
                                            {attendance.photography === '0' && (
                                                <img src={ IconCamera } width="20" height="18" alt="" />
                                            )}
                                            {attendance.notes !== '' && (
                                                <img src={ IconMedical } width="18" height="19" alt="" />
                                            )}
                                            <button type="button" onClick={(e) => toggleStudent(idx)} className="toggle">
                                                <IconArrow width={6} height={10} />
                                            </button>
                                        </div>
                                        <div className="LessonAttendanceRowBody">
                                            <dl>
                                                <dt>Parent</dt>
                                                <dd>{attendance.parent}</dd>
                                                <dt>Telephone</dt>
                                                <dd><a href="tel:{attendance.telephone}">{attendance.telephone}</a></dd>
                                                <dt>Level</dt>
                                                <dd>{attendance.level}</dd>
                                                <dt>Photography</dt>
                                                <dd>{attendance.photography === '0' ? 'No' : 'Yes'}</dd>
                                                <dt>Medical</dt>
                                                <dd>{attendance.notes === '' ? 'No' : 
                                                    <>Yes - <a onClick={(e) => setLessonState({...lessonState, medicalModal: attendance.notes})}>View Notes</a></>
                                                }</dd>
                                            </dl>
                                            <textarea placeholder="Lesson Notes" rows={6} onBlur={(e) => updateAttendanceNotes(idx, e)} defaultValue={attendance.lesson_notes}></textarea>
                                            <button type="button" onClick={(e) => setLessonState({...lessonState, criteriaModal: idx})}>Level/Badge Progress</button>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    )}
                    <Link className="Back" to="/">
                        <span>
                            <IconArrow width={15.91} height={24.6} />
                        </span>
                        Back to Lessons
                    </Link>
                </>
            )}

        </div>
    );
}

export default TimetableView;
