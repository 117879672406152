import { createContext } from "react";
import EventHandler from "../Events";
import ApiRequest, { urlBase64ToUint8Array } from "../../Components/ApiRequest";
import DB from "../DB";
import moment from "moment";

export default class Data {
    eventHandler: EventHandler;
    apiRequest: ApiRequest;
    db: DB;

    constructor(eventHandler: EventHandler, apiRequest: ApiRequest) {
        this.eventHandler = eventHandler;
        this.apiRequest = apiRequest;
        this.db = new DB();

        this.eventHandler.on('network:change', this.networkChange.bind(this));

        this.eventHandler.on('profile:request', this.requestProfile.bind(this));
        this.eventHandler.on('profile:request-remote', this.requestRemoteProfile.bind(this));
        this.eventHandler.on('profile:request-local', this.requestLocalProfile.bind(this));
        this.eventHandler.on('profile:update-local', this.updateLocalProfile.bind(this)); 

        this.eventHandler.on('lessons:request', this.requestLessons.bind(this));
        this.eventHandler.on('lessons:request-remote', this.requestRemoteLessons.bind(this));
        this.eventHandler.on('lessons:request-local', this.requestLocalLessons.bind(this));
        this.eventHandler.on('lessons:update-local', this.updateLocalLessons.bind(this));
        this.eventHandler.on('lesson:request', this.requestLesson.bind(this));
        this.eventHandler.on('lesson:request-remote', this.requestRemoteLesson.bind(this));
        this.eventHandler.on('lesson:request-local', this.requestLocalLesson.bind(this));
        this.eventHandler.on('lesson:update-local', this.updateLocalLesson.bind(this));

        this.eventHandler.on('attendances:request', this.requestLessonAttendances.bind(this));

        this.eventHandler.on('actions:add', this.addAction.bind(this));
        this.eventHandler.on('actions:sync', this.syncActions.bind(this));

        this.eventHandler.on('logout', this.logout.bind(this));
    }

    getApi() {
        return this.apiRequest;
    }

    async networkChange(online: any) {
        if (online) {
            this.eventHandler.trigger('actions:sync');
        }
    }

    async requestProfile() {
        if (!this.apiRequest.authentication.check()) {
            return;
        }
        this.eventHandler.trigger('profile:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteProfile() {
        try {
            const body = await this.apiRequest.get('/app/profile');
            this.eventHandler.trigger('profile:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote profile, reverting to local profile.');
            this.eventHandler.trigger('profile:request-local');
        }
    }

    async requestLocalProfile() {
        const profile = await this.db.get('profile', this.apiRequest.authentication.userId());
        this.eventHandler.trigger('profile:receive', profile, 'local');
    }

    async updateLocalProfile(profile: any, from: any) {
        if (!this.apiRequest.authentication.userId()) {
            this.apiRequest.authentication.setUserId(profile.id);
        }
        await this.db.put('profile', profile);
        this.eventHandler.trigger('profile:receive', profile, from);
    }


    async requestLessons() {
        this.eventHandler.trigger('lessons:request-' + (navigator.onLine ? 'remote' : 'local'));
    }

    async requestRemoteLessons() {
        try {
            const body = await this.apiRequest.get('/app/lessons');
            this.eventHandler.trigger('lessons:update-local', body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote lessons, reverting to local lessons.');
            this.eventHandler.trigger('lessons:request-local');
        }
    }

    async requestLocalLessons() {
        let lessons = await this.db.getAll('lessons');
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            lessons = await Promise.all(lessons.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        this.eventHandler.trigger('lessons:receive', lessons, 'local');
    }

    async updateLocalLessons(lessons: any, from: any) {
        await this.db.clear('lessons');
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            lessons = await Promise.all(lessons.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        await this.db.bulkPut('lessons', lessons);
        this.eventHandler.trigger('lessons:receive', lessons, from);
    }

    async requestLessonAttendances(courseId: any, date: any) {
        let attendances = await this.db.getAll('lessons', false, {course_id: courseId.toString(), date});
        const actions = await this.db.getAll('actions', false);
        if (actions.length) {
            // Apply all pending actions.
            attendances = await Promise.all(attendances.map(async (lesson: any, idx: any) => {
                const changes = await actions.filter((action: any) => action.action_type === 'lesson' && Number.parseInt(action.action_id) === Number.parseInt(lesson.id));
                changes.forEach((change: any) => {
                    Object.keys(change.payload).forEach((key: any) => {
                        this.db.getOrSet(lesson, key, change.payload[key]);
                    });
                });
                return lesson;
            }));
        }
        this.eventHandler.trigger('attendances:receive:' + courseId, attendances);
    }

    async requestLesson(lessonId: any) {
        // this.eventHandler.trigger('lesson:request-' + (navigator.onLine ? 'remote' : 'local'), lessonId);
        this.eventHandler.trigger('lesson:request-local', lessonId);
    }

    async requestRemoteLesson(lessonId: any) {
        try {
            const body = await this.apiRequest.get('app/lessons/' + lessonId);
            this.eventHandler.trigger('lesson:update-local', lessonId, body, 'remote');
        } catch (e) {
            console.error('Failed to fetch remote lesson, reverting to local lessons.');
            this.eventHandler.trigger('lesson:request-local', lessonId);

        }
    }

    async requestLocalLesson(lessonId: any) {
        const lesson = await this.db.get('lessons', {id: lessonId.toString()});
        this.eventHandler.trigger('lesson:receive:' + lessonId, lesson);
    }

    async updateLocalLesson(lessonId: any, changes: any, createAction: boolean = false) {
        await this.db.update('lessons', Number.parseInt(lessonId), changes);
        if (createAction) {
            await this.db.add('actions', {type: 'lesson', lessonId, changes});
            this.eventHandler.trigger('actions:sync');
        }
        this.eventHandler.trigger('lesson:request-local', lessonId);
    }

    async addAction(action_type: any, action_id: any, payload: any) {
        await this.db.add('actions', {action_type, action_id, payload, created_at: moment().format('YYYY-MM-DD HH:mm:ss')});
    }

    async syncActions() {
        if (navigator.onLine) {
            const actions = await this.db.getAll('actions');
            if (actions.length) {
                actions.forEach(async (action: any) => {
                    try {
                        switch (action.action_type) {
                            case 'lesson':
                                await this.apiRequest.post('/app/lesson/' + action.action_id, action.payload);
                                break;
                            default:
                                return true;
                        }
                        this.db.delete('actions', Number.parseInt(action.id));
                    } catch (e) {
                        console.error('Failed to sync action: ' + action.id);
                    }
                });
            }
        }
        if (this.apiRequest.authentication.check()) {
            this.eventHandler.trigger('lessons:request');
        }
    }


    async logout() {
        this.apiRequest.authentication.logout();
        await this.db.flush();
        await this.db.init();
    }
}

export const DataContext = createContext(null as any);