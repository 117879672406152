import Login from '../Login';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './index.scss';
import Private from '../Layout/Private';
import Public from '../Layout/Public';
import NotFound from '../NotFound';
import TimetableList from '../Timetable/List';
import TimetableView from '../Timetable/View';
import { useServiceWorker } from '../useServiceWorker';
import { useContext, useEffect, useState } from 'react';
import Modal from '../Components/Modal';
import { EventContext } from '../Contexts/Events';
import Debug from '../Debug';
import Token from '../Token';
import Install from '../Install';
import Installed from '../Installed';

function App() {

  const { waitingWorker, showReload, reloadPage } = useServiceWorker();
  const [updateState, setUpdateState] = useState(false);
  const eventHandler = useContext(EventContext);

  useEffect(() => {
    if (showReload && waitingWorker) {
      setUpdateState(true);
    }
    const interval = setInterval(() => {
      eventHandler.trigger('notifications:request');
    }, 5000);
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    }
  }, [waitingWorker, showReload, eventHandler]);

  return (
    <BrowserRouter>
      {updateState && (
        <Modal onDismiss={() => setUpdateState(false)} title="Update Available">
          <p>An update is available for the app.</p>
          <button className="PrimaryButton" onClick={() => reloadPage()}>Update</button>
        </Modal>
      )}
      <Routes>
        <Route path="/" element={<Private />}>
          <Route index element={<TimetableList />} />
          <Route path="lessons/view/:courseId" element={<TimetableView />} />
          <Route path="debug" element={<Debug />} />
        </Route>
        <Route path="/" element={<Public />}>
          <Route path="token/:token" element={<Token />} />
          <Route path="login" element={<Login />} />
          <Route path="install" element={<Install />} />
          <Route path="installed" element={<Installed />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
